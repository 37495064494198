.group {
    font-family: Helvetica, Arial, sans-serif;
    background-color: #003b00;
    border: solid thin #06ff06;
    display: block;
    position: absolute;
    box-sizing: border-box;
    border-radius: 12pt;
    cursor: pointer;
    box-shadow: rgba(0,0,0, 0.5) 3px 3px 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: none;
}

    .group .group-title {
        display: inline-block;
        position: absolute;
        top: -12pt;
        left: -1px;
        width: auto;
        max-width: 100%;
        text-overflow: ellipsis;
        height: 20pt;
        font-size: 10pt;
        border-top-right-radius: 6pt;
        border-top-left-radius: 6pt;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-style: solid;
        border-left-width: 1px;
        padding-top: 2pt;
        padding-left: 24px;
        padding-right: 24px;
        opacity: 1;
    }

    .group.temporary {
        background-color: transparent;
        border: dotted medium #cccccc;
    }

    .group.temporary .group-title {
        display: none;
    }

    .group.invisible {
        opacity: 0;
    }