.list-button {
    height: 1.5em;
    padding: .25em;
    border-radius: .25em;
    border-style: solid;
    border-width: thin;
    text-overflow: ellipsis;
    margin: .25em;
    text-align: center;
}

.list-button span {
    padding: .25em;
}