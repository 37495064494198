.rectangle-button {
    font-family:  Helvetica, Arial, sans-serif;
    background-color: #003b00;
    border: solid thin #06ff06;
    display: block;
    position: absolute;
    box-sizing: content-box;
    text-align: center;
    border-radius: 12pt;
    cursor:pointer;
    box-shadow: rgba(0,0,0, 0.5) 6px 6px 3px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: none;
}

    .rectangle-button:active {
        box-shadow: rgba(0,0,0, 0.5) 0px 0px;
        opacity: .5;
    }

    .rectangle-button > table {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .rectangle-button > td {
        vertical-align: middle;
    }

.rectangle-button.ellipse {
    border-radius: 50%;
}

.rectangle-button > h1 {
    font-size: 1em;
    line-height: 1.2em;
}

.rectangle-button svg {
    position:absolute;
    top:0;
    left:0;

}