.grab-handle {
    background-color: rgba(255,255,255,.5);
    margin-left: -8px;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    position: absolute;
    box-sizing: border-box;
}

.grab-handle.side {
    border-radius: 2px;
}

.grab-handle.corner {
    border-radius: 1rem;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
    z-index: 999999;
}

.grab-handle.center {
    background: none;
    background-color: rgba(255,255,255,.5);
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    border-radius: 100%;
}