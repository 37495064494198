.viewport-indicator {
    position: absolute;
    background-color: transparent;
    border: dashed medium rgba(255,255,255,.25);
    box-sizing: border-box;
    pointer-events: none;
    overflow: clip;
}

    .viewport-indicator.ad {
        text-align: center;
    }

    .viewport-indicator.ad p {
        color: #cccccc;
        text-align: center;
        margin: auto;
        vertical-align: middle;
        display: inline;
        margin-top: 25px;
        display:inline-block;
    }

    .viewport-indicator a {
        pointer-events: all;
    }