.download-link {
    text-decoration: none;
    color: royalblue;
    margin-left: 30px;
}

.disabled-link {
    text-decoration: none;
    color: #CCCCCC;
    margin-left: 30px;
}

