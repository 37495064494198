.menu-button {
    background-color: #222;
    color: white;
    border: thin solid #666666;
    border-top-width: 0;
    border-left-width: 0;
    box-shadow: 0 .25em .5em rgba(0,0,0,.5);
    padding: 1em 1.5em;
    margin: auto;
    border-bottom-right-radius: 1em;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10001;
    box-sizing: border-box;
    cursor: pointer;
    vertical-align: top;
}

    .menu-button:hover {
        opacity: .5;
    }

    .menu-button.open {
        border-bottom-right-radius: 0;
    }

.menu-items {
    cursor: pointer;
    position: absolute;
    z-index: 10000;
    padding-top: 53px;
}

.menu-item {
    color: white;
    font-size: 1.25em;
    background-color: #222222;
    border: thin solid rgba(255, 255, 255, .5);
    box-shadow: 0 .25em .5em rgba(0,0,0,.5);
    border-left-width: 0;
    border-top-width: 0;
    width: 100%;
    height: 2em;
    margin: auto;
    position: relative;
    z-index: 9999;
    opacity: 1;
    display: flex;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    padding: 0.25em 0.75em 0.25em 0.5em;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
}

    .menu-item:hover {
        font-weight: bolder;
    }

    .menu-item:only-child {
        border-top-width: thin;
        border-top-right-radius: .5em;
        border-bottom-right-radius: .5em;
    }

    .menu-item:first-child {
        border-top-width: thin;
        border-top-right-radius: .5em;
    }

    .menu-item:last-child {
        border-bottom-right-radius: .5em;
    }

    .menu-item input {
        width: 100%;
        height: 100%;
        background-color: black;
        color: white;
    }

    .menu-item svg {
        padding-top: 3pt;
        width: 25px;
    }

    .menu-item span {
        padding-left: 8pt;
        white-space: nowrap;
        line-height: 20pt;
    }
