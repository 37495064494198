.log-out {
    padding: 10px;
    min-width: 200px;
    max-width: 775px;
    cursor: pointer;
    background-color: #ffffff;
}

.login-button {
    height: auto;
    width: auto;
    position:relative;
    width: 100%;
}