
.console-info {
    flex: 1 1 auto;
}
.top-right-elements input {
    width: 100%;
    height: 2em;
    background-color: #000;
    color: white;
    border: thin solid rgba(255, 255, 255, .5);
    box-shadow: 0 .25em .5em rgba(0,0,0,.5);
    border-top-width: 0;
    border-right-width: 0;
    font-weight: bold;
    font-size: 1em;
    line-height: 2em;
    text-align: center;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

        .top-right-elements input:last-child {
            border-bottom-left-radius: .5em;
        }


        .top-right-elements input:enabled {
            color: black;
            background-color: white;
            box-shadow: rgba(0,0,0,0.25) 2px 5px 5px inset;
        }


    .top-right-elements .name-key-combo {
        font-size: 10pt;
        margin: 0 .5em;
    }
