.ad {
    background-color: white;
    border: thick inset #333333;
    flex: 0 0 150px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color:#333333;
}

.standalone .ad {
    display: none;
}

.ad-horizontal {
    display: inline-block;
    height: 150px;
    max-height: 15%;
    width: 100%;
}

.ad-vertical {
    height: 100%;
    width: 150px;
    max-width: 15%;
    display: inline-block;
}