.user-info {
    display: inline-block;
    height: 50px;
    width: 50px;
    border: thin solid rgba(255, 255, 255, .5);
    border-radius: 50%;
    right: 191px;
    box-shadow: rgba(0,0,0, 0.5) 1px 3px 3px;
    background-color: black;
    margin-right: 10pt;
    flex: 0 0 50px;
}

    .user-info:hover {
        opacity: .5;
        box-shadow: none;
    }

    .user-info img {
        height: 40px;
        margin: 5px;
        cursor: pointer;
        border-radius: 50%; 
    }

    .user-info a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        display: inline-block;
        position: fixed;
        margin: 5px;
        box-shadow: 0
    }

    .user-info svg {
        color: #ffffff;
        height: 40%;
        width: 40%;
        padding: 30%;
    }

