.toolbar {
    position: absolute;
    top: .5em;
    left: 75px;
}
.tool-button {
    box-sizing: border-box;
    font-size: .75em;
    position: relative;
    height: 2.5em;
    width: 2.5em;
    background-color: #666666;
    color: #cccccc;
    border-radius: 6pt;
    box-shadow: 0 .25em .5em rgba(0,0,0,.5);
    z-index: 9999;
    cursor: pointer;
    border-style: none;
    font-size: 1em;
    margin-left: 1em;
    opacity: 1;
}

.tool-button:hover {
    opacity: .5;
}

.tool-button.toggled {
    color: #666666;
    z-index: 9998;
    background-color: transparent;
    border: thick solid #666666;
}

.tool-button.new {
    margin-left: 1em;
    color: white;
    background-color: green;
}

    .tool-button.new:active {
        background-color: transparent;
        border: thick solid green;
        color: green;
    }

.tool-group .tool-button {
    border-radius: 0;
    margin-left: 0;
}

    .tool-group .tool-button:first-of-type {
        border-top-left-radius: 6pt;
        border-bottom-left-radius: 6pt;
        margin-left: 1em;
    }

    .tool-group .tool-button:last-of-type {
        border-top-right-radius: 6pt;
        border-bottom-right-radius: 6pt;
    }

    .portrait .toolbar {
        top: 55px;
        left: 0.5em;
    }

.portrait .tool-button {
    display: block;
    margin-left: 0;
    margin-top: 1em;
}

.portrait .tool-group .tool-button {
    border-radius: 0;
    margin: 0;
}

    .portrait .tool-group .tool-button:first-of-type {
        border-top-left-radius: 6pt;
        border-top-right-radius: 6pt;
        margin-top: 1em;
    }

    .portrait .tool-group .tool-button:last-of-type {
        border-bottom-left-radius: 6pt;
        border-bottom-right-radius: 6pt;
    }
