.properties-panel-toggle {
    display: inline-block;
    position: fixed;
    margin-top: 22pt;
    margin-left: -24pt;
    height: 60pt;
    width: 24pt;
    line-height: 60pt;
    text-align: center;
    background-color: #222222;
    border-bottom-left-radius: 4.5pt;
    border-top-left-radius: 4.5pt;
    border-top: solid thin rgba(255,255,255,0.5);
    cursor: pointer;
    box-sizing: content-box;
}

.portrait .properties-panel-toggle {
    right: 0;
    margin-top: -24pt;
    line-height: 24pt;
    width: 60pt !important;
    height: 24pt !important;
}
