.properties-panel {
    position: fixed;
    height: auto;
    background-color: #222;
    box-sizing: border-box;
    box-shadow: 0em 0em 0.3em #111;
    color: white;
    z-index: 9999;
    overflow-y: hidden;
    padding: 0;
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    max-width: 28em;
}

.landscape .properties-panel.collapsed {
    width: 0 !important;
    min-width: 0 !important;
    overflow: hidden;
}

.portrait .properties-panel.collapsed {
    height: 0 !important;
    min-height: 0 !important;
    overflow: hidden;
}

.landscape .properties-panel {
    width: 25%;
    min-width: 18em;
    top: 0;
    bottom: 0;
    right: 0;
}

.portrait .properties-panel {
    height: 40%;
    bottom: 0;
    right: 0;
    width: 100%;
}

.properties-panel-tabs {
    display: flex;
    flex-direction: row;
}

    .properties-panel-tabs h1 {
        width: 100%;
        box-sizing: border-box;
        height: 2.5em;
        padding-top: .5em;
        margin: 0;
        top: 0;
        display: block;
        color: white;
        border: 0 solid rgba(255, 255, 255, .5);
        border-top-width: thin;
        font-size: .75em;
        text-align: center;
        font-weight: normal;
        background-color: #666666;
        cursor: pointer;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        user-select: none;
        z-index: 1000;
    }

        .properties-panel-tabs h1.selected {
            background-color: #222;
            font-weight: bolder;
        }

        .properties-panel-tabs h1:first-child {
            border-left-width: 0;
        }

        .properties-section {
            overflow-y: scroll;
            box-sizing: border-box;
            background-color: #222;
            width: 100%;
            border-bottom: solid thin rgba(255,255,255, .5);
        }

    .properties-section::-webkit-scrollbar {
        width: 0.75em; /* width of the entire scrollbar */
    }

    .properties-section::-webkit-scrollbar-track {
        background: #222; /* color of the tracking area */
    }

    .properties-section::-webkit-scrollbar-thumb {
        background-color: #666; /* color of the scroll thumb */
        border-radius: 0.3em; /* roundness of the scroll thumb */
        border: 0.2em solid #222; /* creates padding around scroll thumb */
    }

.properties-panel h2 {
    height: 1.25em;
    line-height: 1.25em;
    margin: .25em 0 0 0;
    padding: 0.25em;
    text-align: left;
    display: block;
    padding: .25em 0 .25em 1em;
    color: #eee;
    background: linear-gradient(to right,#333, #222 40%, #222);
    font-size: .75em;
    font-weight: normal;
    cursor: pointer;
    user-select: none;
}

.properties-panel .input {
    margin-bottom: 5px;
}

.properties-panel .navigation {
    border-radius: 0;
    box-shadow: none;
    background-color: #333;
    color: white;
    margin-bottom: 0.2em;
    text-align: left;
    padding: 0.3em;
    width: 20em;
    height: 2em;
    margin-left: auto;
    margin-right: auto;
}

    .properties-panel .navigation:hover {
        background-color: #444;
    }

    .properties-panel .navigation.selected {
        background-color: #666;
    }

.properties-panel label {
    display: block;
    margin-bottom: 0.3em;
    font-size: .75em;
}

.properties-panel input, .properties-panel select {
    padding: .25em 1em;
    border: none;
    border-radius: 0.6em;
    display: block;
    box-shadow: 0.125em 0.125em 0.125em inset;
    height: 1.25em;
    font-size: .75em;
    line-height: 1em;
}

.properties-panel .check-input {
    height: 1.3em;
    margin-left: auto;
    margin-right: auto;
    
}

    .properties-panel .check-input label {
        display: inline-block;
        position: relative;
        padding: 0;
        top: -.3em;
        height: 1.25em;
    }

    .properties-panel .check-input input[type=checkbox] {
        display: inline-block;
        height: 1.25em;
        width: 1.25em;
        margin-left: .5em;
        box-shadow: none;
    }

.properties-panel button.inline-input {
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    margin: 0;
    padding: 3px;
}

.properties-panel .inline-input {
    display: flex;
    flex-direction: row;
    max-width: 15em;
    justify-content: center;
    padding: 0 0.5em;
    margin-left: auto;
    margin-right: auto;
}

    .properties-panel .inline-input label {
        display: inline-block;
        width: 7em;
        height: 2em;
        line-height: 2em;
        margin: 0;
        padding: 3px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .properties-panel .inline-input span {
        display: inline-block;
        height: 2em;
        line-height: 2em;
        padding: 3px;
        font-size: .75em;
        margin: 0;
    }

    .properties-panel .inline-input input, .properties-panel .inline-input select {
        display: inline-block;
        flex: 1 1 auto;
        min-width: 0;
        width: 0;
        box-sizing: content-box;
        margin-right: .5em;
        max-width: 15em;
        height: 2em;
    }

        .properties-panel input[type=number] {
            text-align: right;
        }


        .properties-panel .inline-input input[type=color] {
            background: transparent;
            padding: 0;
            box-shadow: none;
            height: 2em;
            margin-top: .25em;
            display: inline-block;
        }

.properties-panel .inline-input input[type=checkbox] {
    box-shadow: none;
}
.properties-panel button {
    color: white;
    background-color: #666666;
    font-weight: bold;
    box-sizing: border-box;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: 0.5em;
    margin-bottom: .5em;
    height: 2.5em;
    padding: 0.3em 1em;
    width: 100%;
    border: none;
    border-radius: .6em;
    display: block;
    box-shadow: 0 .125em .125em black;
    cursor: pointer;
}

    .properties-panel button:hover {
        box-shadow: 0 0 0;
        background-color: transparent;
        font-weight: bolder;
        border: solid thick #666666;
        padding: 0;
    }

    .properties-panel button:active {
        background-color: #111;
        box-shadow: 0 0 0;
    }

.properties-group {
    padding: .3em;
    margin: 0 .3em .3em .3em;
}

.properties-panel .add-button {
    background-color: green;
}
    .properties-panel .add-button:hover {
        border: solid thick green;
    }

.properties-panel .delete-button {
    background-color: red;
}

    .properties-panel .delete-button:hover {
        border: solid thick red;
    }


.closed {
    display: none;
}

h2.closed {
    display: inherit;
}

h2.closed::before {
    content: ">  "
}