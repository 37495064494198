.simple-page {
    background: linear-gradient(to Bottom, #111111, #000000);
    position: relative;
    height: 100%;
    width: 100%;
}

    .simple-page .user-info {
        top: 10px;
        right: 20px;
        position: absolute;
    }

    .simple-page .back-button {
        display: inline-block;
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width: 66.6px;
        background-color: transparent;
        color: #cccccc;
        border-right: solid thin #666666;
    }
        .simple-page .back-button svg {
            height: 40%;
            width: 40%;
            margin: 30%;
        }

    .simple-page .icon-title {
        margin-left: 75px;
        margin-right: 75px;
        display: inline-block;
    }

        .simple-page .icon-title > span {
            display: inline-block;
        }

    .simple-page.portrait .icon-title > svg {
        display: none;
    }


        .page-centerer {
            background-color: transparent;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
        }


.border {
    flex: 1 1 auto;
    background-color: transparent;
}


.center-stack {
    background-color: transparent;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    flex: 0 1 1200px;
}

.page-header {
    flex: 0 0 69px;
    background-color: #222222;
    color:  #cccccc;
    box-sizing: border-box;
    margin: 0px;
    padding: 15px 20px;
    font-family: Tahoma;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.25);
    z-index: 2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}

.page-header svg {
    margin-right: 25px;
}

.content-holder { 
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    max-height: 100%;
    position: relative;
    overflow: hidden;
}


.navigation {
    flex: 0 0 142px;
    background-color: #333333;
    box-shadow: 3px 0 3px rgba(0,0,0,0.25);
    z-index: 1;
    border-bottom-left-radius: 10px;
    white-space: nowrap;
}

.collapsed .navigation {
    flex: 0 0 60px;
}

    .navigation > a, .navigation > div {
        display: block;
        border-bottom-width: thin;
        border-color: black;
        border-bottom-style: solid;
        padding: 10px 20px;
        color: white;
        text-decoration: none;
        box-sizing: border-box;
    }

.navigation .collapse {
    position: absolute;
    bottom: 0;
    border-top-style: solid;
    border-top-width: thin;
    text-align: center;
    width: 142px;
}

.collapsed .navigation .collapse {
    width: 60px;
}

        .collapsed .navigation a span {
            display: none;
        }

        .navigation > a:hover {
            background-color: white;
            color: #333333;
        }


        .navigation > a.active {
            background-color: #666666;
            color: white;
            font-weight: bold;
            cursor: default;
        }

        .navigation > a > svg {
            width: 20px;
            margin-right: 10px;
        }

        .collapsed .navigation > a > svg {
            margin-right: 0;
        }

.content {
    padding: 20px;
    flex: 1 1 auto;
    background-color: #222222;
    color: white;
    border-bottom-right-radius: 10px;
    overflow-y: auto;
    overflow-x: clip;
    position: relative;

}

.section {
    margin-left: 15px;
    margin-right: 15px;
}

.simple-page h1.page-header {
    line-height: 32pt;
}

.simple-page h1 {
    font-size: 18pt;
    margin-top: 40px;
}

.simple-page h1:first-of-type {
    margin-top: 0;
}

.simple-page h2 {
    font-size: 16pt;
    color: #869da5;
    margin-left: 10px;
}

.simple-page h3 {
    font-size: 14pt;
    color: #456079;
    margin-left: 20px
}

.simple-page p {
    font-size: 12pt;
    padding: 0 20pt;
}

.simple-page li {
    font-size: 12pt;
}

.simple-page .content a,
.simple-page .content a:visited,
.simple-page .content a:active {
    color: #006aff;
}

hr {
    color: rgba(255, 255, 255, .25);
    margin-top: 25px; margin-bottom: 50px;
}

.simple-page button {
    background-color: #666666;
    border-radius: 5px;
    color: #cccccc;
    font-size: 16px;
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 12px;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    user-select: none;
}

    .simple-page button:hover {
        background-color: #66666670;
    }

.table-of-contents {
    border: solid thin white;
    display: inline-block;
    position:relative;
    margin: 20px auto;
    padding: 10px 20px;
    border-radius: 15px;
}

.table-of-contents h1 {
    font-size: 12pt;
    margin: 0;
}

    .table-of-contents ul {
        padding: 20px;
        margin: 0;
    }

    .table-of-contents li {
        list-style-type: "-  ";
        margin-bottom: 5px;
    }

