.store-offering {
    background-color: #AAAAAA;
    color: black;
    max-width: 775px;
    border-radius: 20pt;
    box-shadow: 5px 0 10px rgba(0,0,0, 0.5);
    margin-bottom: 20px;
}

    .store-offering h2 {
        color: #333333;
        width: 100%;
        background-color: white;
        padding: 7pt 20pt 12pt 20pt;
        box-sizing: border-box;
        border-top-right-radius: 20pt;
        border-top-left-radius: 20pt;
        font-size: 18pt;
        margin: 0;
    }

    .store-offering h2 span {
        margin-right: 20px;
    }

    .store-offering h3 {
        color: #006aff;
    }

    .store-offering p {
        margin: 20px 10px;
        color: #333333;
    }


.simple-page .content .store-offering a, 
.simple-page .content .store-offering a:active, 
.simple-page .content .store-offering a:hover {
    color: #006aff;
}
.store-offering i {
    display: block;
    font-size: 12pt;
    padding-top: 12pt;
    text-align: center;
}

.store-offering .offering-contents {
    padding: 0 20pt 20pt 20pt;
}

.store-offering .product-key {
    font-weight: bold;
    font-size: 18pt;
    margin-top: 15pt;
    margin-bottom: 25pt;
    text-align: center;
    display: block;
}

.price-tag {
    color: black;
    background-color: #8abc00;
    padding: 5pt;
    font-family: monospace;
    font-size: 12pt;
    border-radius: 10%;
    display: inline-block;
}

.store-offering sup {
    font-size: .5em;
}

.offering-contents button {
    background-color: rgb(0, 106, 255);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 12px;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    user-select: none;
}