#root {
    position: fixed;
    height: 100%;
    width: 100%;
}

a {
    color: #a6c1cb;
}

    a:hover {
        color: #869da5;
    }

.main-container {
    height: 100%;
    width: 100%;
    display: flex;
    pointer-events: none;
}

    .main-container.landscape {
        flex-direction: row;
    }

    .main-container.portrait {
        flex-direction: column;
    }

.app-content {
    display: inline-block;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    vertical-align: top;
    flex: 1 1 auto;
    pointer-events: none;
}

.app-content > * {
    pointer-events: auto;
}

.selection-box {
    position: fixed;
    background-color: transparent;
    border: dashed thick #CCCCCC;
    box-sizing: border-box;
}

.button-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: 0;
}


.top-right-elements {
    position: absolute;
    height: auto;
    box-sizing: border-box;
    color: white;
    z-index: 9998;
    padding: 0;
    display: flex;
    flex-direction: row;
    top: 0;
    right: 0;
    font-size: 10pt;
    margin-left: 100pt;
    max-width: 288px;
}

.button-panel {
    display: inline-block;
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: -1;
    /* border: thick solid red; */
    box-sizing: border-box;
}

    .button-panel.open-properties.landscape {
        margin-right: 200px;
    }

    .button-panel.open-properties.portrait {
        margin-right: 200px;
    }


* {
    -webkit-tap-highlight-color: transparent;
}