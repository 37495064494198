.button-container-preview {
    position: relative;
    z-index: 0;
    border: solid medium white;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 12pt;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-width: thin;
    box-shadow: 0px 15px 10px rgba(0,0,0,0.5);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

    .button-container-preview > div {
        pointer-events: none;
    }

    .button-container-preview.selected {
        box-shadow: 0px 0px 15px white;
    }

.spacer {
    margin-bottom: 50px;
}

.console-tools {
    position: sticky;
    top: 0;
    left: 0;
    margin-bottom: 20pt;
    z-index: 2;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
}

.console-tools button {
    pointer-events: initial;
}

    .console-tools::-webkit-scrollbar {
        display: none;
    }

    .console-tools div {
        display: inline-block;
        margin: 5pt;
    }

    .console-tools > div > button {
        border-right: solid thin #333333;
        border-radius: 0;
    }

    .console-tools > div > button:first-child {
        border-top-left-radius: 5pt;
        border-bottom-left-radius: 5pt;
    }
        .console-tools > div > button:last-child {
            border-top-right-radius: 5pt;
            border-bottom-right-radius: 5pt;
            border-right: none;
        }

.console-tools button {
    display: inline-block;
    height: 30pt;
    width: 100pt;
    z-index: 2;
    line-height: 12pt;
}
    .console-tools button svg {
        margin-right: 10pt;
    }

.portrait .console-tools button {
    width: 30pt;
}

    .portrait .console-tools button svg {
        margin: 0;
    }

    .portrait .console-tools button span {
        display: none;
    }

.console-controls {
    height: 50px;
    position: relative;
    /*margin-top: -15px;*/
    display: flex;
    flex-direction: row;
    margin-bottom: 75px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 10px 7px rgba(0,0,0, 0.75);
    height: 75px;
    box-sizing: border-box;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

    .console-controls button {
        box-sizing: border-box;
        height: 75px;
        border-radius: 0pt;
        box-shadow: 0 0 15pt rgba(255,255,255,.05);
        font-size: 16pt;
        border: solid thin white;
        pointer-events: initial;
        
        flex: 1 1 150px;
    }

    .console-controls button:hover, .console-controls button:active {
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0.5);
        color: black;
        opacity: 1;
    }

    .console-controls button:first-child {
        border-bottom-left-radius: 15px;
    }

    .console-controls button:last-child {
        border-bottom-right-radius: 15px;
    }

    .console-controls button svg {
        margin-right: 10pt;
    }

        .portrait .console-controls button svg {
            margin-right: 0pt;
        }

        .portrait .console-controls span {
            display: none;
        }


.console-title {
    display: block;
    width: 150pt;
    margin: 0 auto;
    top: 0;
    right: 0;
    border-bottom-left-radius: 15pt;
    background-color: black;
    height: 30pt;
    line-height: 30pt;
    padding: 10pt;
    text-overflow: ellipsis;
    border: solid thin white;
    border-top-width: 0;
    border-right-width: 0;
    box-shadow: 0 10px 7px rgba(0,0,0, 0.75);
    position: absolute;
    user-select: none;
}

.consoles-help svg {
    color: #cccccc;
    margin-left: 5px;
    margin-right: 5px;
}

.consoles-help {
    margin-bottom: 10px;
    display: block;
    padding: 20px;
    text-align: center;
    box-shadow: rgba(0,0,0,0.5) 0 10px 10px;
    background-color: #333333;
}

    .consoles-help b, .consoles-help i {
        display: block;
        padding-bottom: 15px;
    }

    .consoles-help b:last-child, .consoles-help i:last-child {
        padding-bottom: 0;
    }